import React, {useState} from 'react';

import './react-styles/main.scss'
import 'bootstrap/dist/css/bootstrap.min.css';

import { Modal, Button } from 'react-bootstrap';

import IMG_FIND from './imgs/img-find.png'
import IMG_SIMULATION from './imgs/icon-simulation.png'
import IMG_EMAIL from './imgs/icon-email.png'
import IMG_CONTRACT from './imgs/icon-contract.png'
import IMG_PAYMENT from './imgs/icon-payment.png'
import IMG_FALLOW from './imgs/icon-fallow.png'
import IMG_LOGO_WHITE from './imgs/logo-Inconta-white.png'
import IMG_LOGO_GRAY from './imgs/logo-Inconta-gray.png'
import BLOG_1_300 from './imgs/blog-1-300x250.png'
import BLOG_1 from './imgs/blog-1.png'
import BLOG_2_300 from './imgs/blog-2-300x250.png'
import BLOG_2 from './imgs/blog-2.png'
import BLOG_3_300 from './imgs/blog-3-300x250.png'
import BLOG_3 from './imgs/blog-3.png'
import BLOG_4_300 from './imgs/blog-4-300x250.png'
import BLOG_4 from './imgs/blog-4.png'
import IMG_SOLUTI from './imgs/soluti.png'
import IMG_SIMULE from './imgs/simule.png'
import IMG_ALERT from './imgs/modal/alert.jpeg'

  
export default function App() {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <div id="page-container" style={{ overflowY: "hidden" }}>
            <div id="et-boc" className="et-boc">
                <header className="et-l et-l--header">
                    <div className="et_builder_inner_content et_pb_gutters3"><div id="menu-home" className="et_pb_section et_pb_section_0_tb_header et_pb_with_background et_pb_fullwidth_section et_section_regular  menu-home-fixed-header">
                        <div className="et_pb_module et_pb_fullwidth_menu et_pb_fullwidth_menu_0_tb_header et_pb_bg_layout_light  et_pb_text_align_right et_dropdown_animation_fade et_pb_fullwidth_menu--with-logo et_pb_fullwidth_menu--style-left_aligned">
                            <div className="et_pb_row clearfix">
                                <div className="et_pb_menu__logo-wrap">
                                    <div className="et_pb_menu__logo">
                                        <a href="https://incontadigital.com.br/">
                                            <img loading="lazy" src={IMG_LOGO_GRAY} alt="" height="auto" width="auto" className="wp-image-98 fixed-header-gray" />
                                            <img loading="lazy" src={IMG_LOGO_WHITE} alt="" height="auto" width="auto" className="wp-image-98 fixed-header-white" />
                                        </a>
                                    </div>
                                </div>
                                <div className="et_pb_menu__wrap">
                                    <div className="et_pb_menu__menu">
                                        <nav className="et-menu-nav fullwidth-menu-nav"><ul id="menu-navigation-top" className="et-menu fullwidth-menu nav">
                                            <li className="et_pb_menu_page_id-103 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-103"><a href="//simulador.incontadigital.com.br" aria-current="page" data-et-has-event-already="true">Simulador</a></li>
                                            <li className="et_pb_menu_page_id-104 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-104"><a href="#wrapper-inconta" aria-current="page" data-et-has-event-already="true" >Vantagens</a></li>
                                            <li className="et_pb_menu_page_id-105 menu-item menu-item-type-custom menu-item-object-custom menu-item-105"><a href="#wrapper-blog">Blog</a></li>
                                            <li className="et_pb_menu_page_id-106 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-106"><a href="#wrapper-tirar-duvidas" aria-current="page" data-et-has-event-already="true">Dúvidas</a></li>
                                            <li className="et_pb_menu_page_id-107 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-107"><a href="#footer-global" aria-current="page" data-et-has-event-already="true">Contato</a></li>
                                            <li className="et_pb_menu_page_id-108 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-108"><a href="//simulador.incontadigital.com.br/admin" aria-current="page" data-et-has-event-already="true">Administrativo</a></li>
                                        </ul></nav>
                                    </div>


                                    <div className="et_mobile_nav_menu">
                                        <a href="#" className="mobile_nav closed">
                                            <span className="mobile_menu_bar"></span>
                                            <ul id="mobile_menu1" className="et_mobile_menu">
                                                <li className="et_pb_menu_page_id-103 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-103 et_first_mobile_item"><a href="//simulador.incontadigital.com.br" aria-current="page" data-et-has-event-already="true">Simulador</a></li>
                                                <li className="et_pb_menu_page_id-104 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-104"><a href="#wrapper-inconta" aria-current="page" data-et-has-event-already="true">Vantagens</a></li>
                                                <li className="et_pb_menu_page_id-105 menu-item menu-item-type-custom menu-item-object-custom menu-item-105"><a href="#wrapper-blog">Blog</a></li>
                                                <li className="et_pb_menu_page_id-106 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-106"><a href="#wrapper-tirar-duvidas" aria-current="page" data-et-has-event-already="true">Dúvidas</a></li>
                                                <li className="et_pb_menu_page_id-107 menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-107"><a href="#footer-global" aria-current="page" data-et-has-event-already="true">Contato</a></li>
                                            </ul></a>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div> </div>
                </header>
                <div id="et-main-area">

                    <div id="main-content">
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                             
                            </Modal.Header>
                            <Modal.Body>
                                <img src={IMG_ALERT}/>
                            </Modal.Body>
         
                        </Modal>
                        <article id="post-9" className="post-9 page type-page status-publish hentry">


                            <div className="entry-content">
                                <div className="et-l et-l--post">
                                    <div className="et_builder_inner_content et_pb_gutters3">
                                        <div id="wrapper-hero" className="et_pb_section et_pb_section_0 et_pb_with_background et_section_regular">




                                            <div id="wapper-hero-simulator" className="et_pb_row et_pb_row_0">
                                                <div className="et_pb_column et_pb_column_3_5 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough top-text" >


                                                    <div className="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_left et_pb_bg_layout_light">


                                                        <div className="et_pb_text_inner"><h1>Crédito Online<br /> De quanto precisa?</h1></div>
                                                    </div> <div className="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">



                                                    </div>
                                                </div>
                                                <div className="et_pb_column et_pb_column_2_5 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough et-last-child ajust-buttom-simule">


                                                    <div className="et_pb_module et_pb_code et_pb_code_0 button-simule">
                                                        <a href="//simulador.incontadigital.com.br"><img src={IMG_SIMULE} className="img-simule " /></a>
                                                    </div>
                                                </div>


                                            </div>


                                        </div> <div className="et_pb_section et_pb_section_1 et_pb_with_background et_section_regular" >




                                            <div className="et_pb_row et_pb_row_1">
                                                <div className="et_pb_column et_pb_column_4_4 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child ">


                                                    <div className="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_center et_pb_bg_layout_light">


                                                        <div className="et_pb_text_inner"><h4 className="p1">Somos uma plataforma digital para levar a melhor opção de crédito até você!</h4></div>
                                                    </div>
                                                </div>


                                            </div>


                                        </div> <div id="wrapper-inconta" className="et_pb_section et_pb_section_2 et_pb_with_background et_section_regular">




                                            <div className="et_pb_row et_pb_row_2">
                                                <div className="et_pb_column et_pb_column_1_2 et_pb_column_3  et_pb_css_mix_blend_mode_passthrough">


                                                    <div className="et_pb_module et_pb_text et_pb_text_3  et_pb_text_align_left et_pb_bg_layout_light">


                                                        <div className="et_pb_text_inner"><h2>Inconta é <strong>rápido, fácil</strong><br /> e <strong>transparente</strong></h2></div>
                                                    </div> <div className="et_pb_module et_pb_image et_pb_image_0">


                                                        <span className="et_pb_image_wrap "><img loading="lazy" src={IMG_FIND} alt="" title="img-find" height="auto" width="auto" srcSet={`${IMG_FIND} 682w, ${IMG_FIND} 480w`} sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 682px, 100vw" className="wp-image-32" /></span>
                                                    </div>
                                                </div> <div className="et_pb_column et_pb_column_1_2 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                                    <div className="et_pb_module et_pb_accordion et_pb_accordion_0">


                                                        <div className="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_0  et_pb_toggle_open">


                                                            <h5 className="et_pb_toggle_title">Crédito Consignado</h5>
                                                            <div className="et_pb_toggle_content clearfix">É uma operação de crédito (empréstimo pessoal) cujo pagamento é descontado diretamente, em parcelas mensais fixas e sucessivas, da folha de pagamento ou do benefício previdenciário do contratante. Trata-se de uma das linhas de crédito com menores taxas de juros no mercado financeiro. Além da praticidade do pagamento de suas parcelas a contratação no InConta também é bastante simples.</div>
                                                        </div> <div className="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_1  et_pb_toggle_close">


                                                            <h5 className="et_pb_toggle_title">Faça seu cadastro</h5>
                                                            <div className="et_pb_toggle_content clearfix">De forma prática e fácil e com algumas informações você consegue acessar as melhores soluções financeiras do mercado sem sair de casa. Através de seu smartphone ou computador poderá contratar sem burocracia o seu crédito consignado.</div>
                                                        </div> <div className="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_2  et_pb_toggle_close">


                                                            <h5 className="et_pb_toggle_title">É Seguro</h5>
                                                            <div className="et_pb_toggle_content clearfix"> A atuação do InConta é respaldada pelas determinações do Banco Central do Brasil, cumprindo integralmente diretrizes e normas. Além da experiência profissional e de muito respeito aos clientes e parceiros, o InConta entende a importância de suas informações e necessidades.</div>
                                                        </div> <div className="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_3  et_pb_toggle_close">


                                                            <h5 className="et_pb_toggle_title">É Digital</h5>
                                                            <div className="et_pb_toggle_content clearfix">Sem papéis, deslocamentos, filas, a plataforma digital do InConta busca conectar você ao crédito. Trata-se de um processo inteiramente digital para que além de excelentes condições você também economize tempo.</div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>


                                        </div> <div id="wrapper-blog" className="et_pb_section et_pb_section_3 et_pb_with_background et_section_regular">




                                            <div className="et_pb_row et_pb_row_3">
                                                <div className="et_pb_column et_pb_column_1_2 et_pb_column_5  et_pb_css_mix_blend_mode_passthrough">


                                                    <div className="et_pb_module et_pb_text et_pb_text_4  et_pb_text_align_left et_pb_bg_layout_light">


                                                        <div className="et_pb_text_inner"><h2>Acompanhe <strong>dicas</strong> e muito<br /> mais em nosso <strong>Blog</strong></h2></div>
                                                    </div> <br />
                                                </div>


                                            </div> <div className="et_pb_row et_pb_row_4">
                                                <div className="et_pb_column et_pb_column_4_4 et_pb_column_7  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                                    <div className="et_pb_with_border et_pb_module et_pb_blog_0 q4-blog et_animated et_pb_blog_grid_wrapper" data-animation-style="fade" data-animation-repeat="" data-animation-duration="1000ms" data-animation-delay="0ms" data-animation-intensity="50%" data-animation-starting-opacity="0%" data-animation-speed-curve="ease-in-out">
                                                        <div className="et_pb_blog_grid clearfix ">


                                                            <div className="et_pb_ajax_pagination_container">
                                                                <div className="et_pb_salvattore_content" data-columns="4"><div className="column size-1of4"><article id="post-48" className="et_pb_post clearfix et_pb_blog_item_0_0 post-48 post type-post status-publish format-standard has-post-thumbnail hentry category-categoria-1 category-categoria-2 tag-tag-1 tag-tag-2">

                                                                    <div className="et_pb_image_container"><a href="https://incontadigital.com.br/2020/08/29/educacao-financeira-e-sua-vantagens/" className="entry-featured-image-url"><img loading="lazy" src={BLOG_1_300} alt="EDUCAÇÃO FINANCEIRA E SUA VANTAGENS" className="" srcSet={`${BLOG_1} 479w, ${BLOG_1_300} 480w `} sizes="(max-width:479px) 479px, 100vw " width="400" height="250" /></a></div> <br />
                                                                    <h2 className="entry-title"><a href="https://incontadigital.com.br/2020/08/29/educacao-financeira-e-sua-vantagens/">EDUCAÇÃO FINANCEIRA E SUAS VANTAGENS</a></h2>

                                                                    <p className="post-meta"></p><div className="post-content"><div className="post-content-inner"><p>As máquinas oferecem ao clien- te, apenas a facilidade de expe- riência, mas nós oferecemos a simplicidade em alcançar um sonho. Ser simples é mais fácil, é mais rápido e mais inteligente.</p>
                                                                    </div><a href="https://incontadigital.com.br/2020/08/29/educacao-financeira-e-sua-vantagens/" className="more-link">veja mais...</a></div>
                                                                </article></div><div className="column size-1of4"><article id="post-46" className="et_pb_post clearfix et_pb_blog_item_0_1 post-46 post type-post status-publish format-standard has-post-thumbnail hentry category-categoria-1 category-categoria-2 tag-tag-1 tag-tag-2">

                                                                    <div className="et_pb_image_container"><a href="https://incontadigital.com.br/2020/08/29/posso-comprar-um-imovel-como-investimento/" className="entry-featured-image-url"><img loading="lazy" src={BLOG_2_300} alt="POSSO COMPRAR UM IMÓVEL COMO INVESTIMENTO?" className="" srcSet={`${BLOG_2} 479w, ${BLOG_2_300} 480w `} sizes="(max-width:479px) 479px, 100vw " width="400" height="250" /></a></div> <br />
                                                                    <h2 className="entry-title"><a href="https://incontadigital.com.br/2020/08/29/posso-comprar-um-imovel-como-investimento/">POSSO COMPRAR UM IMÓVEL COMO INVESTIMENTO?</a></h2>

                                                                    <p className="post-meta"></p><div className="post-content"><div className="post-content-inner"><p>Quem nunca ouviu aquela máxima de “Quem compra terra não erra”, trata-se de um ditado popular que muitos já ouviram, leram e até já reproduziram, mas será que ele está correto?</p>
                                                                    </div><a href="https://incontadigital.com.br/2020/08/29/posso-comprar-um-imovel-como-investimento/" className="more-link">veja mais...</a></div>
                                                                </article></div><div className="column size-1of4"><article id="post-44" className="et_pb_post clearfix et_pb_blog_item_0_2 post-44 post type-post status-publish format-standard has-post-thumbnail hentry category-categoria-1 category-categoria-2 tag-tag-1 tag-tag-2">

                                                                    <div className="et_pb_image_container"><a href="https://incontadigital.com.br/2020/08/29/o-consignado-com-inconta/" className="entry-featured-image-url"><img loading="lazy" src={BLOG_3_300} alt="O CONSIGNADO COM INCONTA" className="" srcSet={`${BLOG_3} 479w, ${BLOG_3_300} 480w `} sizes="(max-width:479px) 479px, 100vw " width="400" height="250" /></a></div> <br />
                                                                    <h2 className="entry-title"><a href="https://incontadigital.com.br/2020/08/29/o-consignado-com-inconta/">O CONSIGNADO COM INCONTA</a></h2>

                                                                    <p className="post-meta"></p><div className="post-content"><div className="post-content-inner"><p>O crédito consignado constitui-se como uma linha de crédito extremamente barata comparada com outras modalidades de empréstimos pessoais…</p>
                                                                    </div><a href="https://incontadigital.com.br/2020/08/29/o-consignado-com-inconta/" className="more-link">veja mais...</a></div>
                                                                </article></div><div className="column size-1of4"><article id="post-38" className="et_pb_post clearfix et_pb_blog_item_0_3 post-38 post type-post status-publish format-standard has-post-thumbnail hentry category-categoria-1 category-categoria-2 tag-tag-1 tag-tag-2">

                                                                    <div className="et_pb_image_container"><a href="https://incontadigital.com.br/2020/08/29/credito-consciente/" className="entry-featured-image-url"><img loading="lazy" src={BLOG_4_300} alt="CRÉDITO CONSCIENTE" className="" srcSet={`${BLOG_4} 479w, ${BLOG_4_300} 480w `} sizes="(max-width:479px) 479px, 100vw " width="400" height="250" /></a></div> <br />
                                                                    <h2 className="entry-title"><a href="https://incontadigital.com.br/2020/08/29/credito-consciente/">CRÉDITO CONSCIENTE</a></h2>

                                                                    <p className="post-meta"></p><div className="post-content"><div className="post-content-inner"><p>Crédito precisa ser saudável. Tanto para quem concede o crédito, as instituições financeiras, quanto para quem utiliza o crédito, os consumidores.</p>
                                                                    </div><a href="https://incontadigital.com.br/2020/08/29/credito-consciente/" className="more-link">veja mais...</a></div>
                                                                </article></div></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>


                                        </div> <div id="wrapper-title-seu-dinheiro" className="et_pb_section et_pb_section_4 et_pb_with_background et_section_regular">




                                            <div className="et_pb_row et_pb_row_5">
                                                <div className="et_pb_column et_pb_column_1_2 et_pb_column_8  et_pb_css_mix_blend_mode_passthrough">


                                                    <div className="et_pb_module et_pb_text et_pb_text_5  et_pb_text_align_left et_pb_bg_layout_light">


                                                        <div className="et_pb_text_inner"><h2>SEU <strong>DINHEIRO</strong> SEM COMPLICAÇÃO</h2></div>
                                                    </div> <br /><div className="et_pb_module et_pb_text et_pb_text_6  et_pb_text_align_left et_pb_bg_layout_light">


                                                        <div className="et_pb_text_inner"><p>Veja aqui o PASSO A PASSO para você solicitar<br /> agora seu empréstimo consignado</p></div>
                                                    </div> <br />
                                                </div> <div className="et_pb_column et_pb_column_1_2 et_pb_column_9  et_pb_css_mix_blend_mode_passthrough et-last-child et_pb_column_empty">



                                                </div>


                                            </div>


                                        </div> <div id="wrapper-seu-dinheiro" className="et_pb_section et_pb_section_5 et_pb_with_background et_section_regular">




                                            <div id="wrapper-carousel" className="et_pb_row et_pb_row_6 et_pb_equal_columns et_pb_row_5col">
                                                <div className="et_pb_column et_pb_column_1_5 et_pb_column_10  et_pb_css_mix_blend_mode_passthrough">


                                                    <div className="et_pb_module et_pb_blurb et_pb_blurb_0 seu-dinheiro-blurb-item  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">


                                                        <div className="et_pb_blurb_content">
                                                            <div className="et_pb_main_blurb_image"><span className="et_pb_image_wrap"><img loading="lazy" width="174" height="181" src={IMG_SIMULATION} alt="" className="et-waypoint et_pb_animation_top wp-image-66" /></span></div>
                                                            <div className="et_pb_blurb_container">
                                                                <h4 className="et_pb_module_header"><span>FAÇA UMA SIMULAÇÃO</span></h4>
                                                                <div className="et_pb_blurb_description"><div className="number"><span>01</span></div>
                                                                    <p>Utilize nosso simulador e veja quanto você precisa. Faça seu cadastro online.<br /> É digital e é seguro.</p></div>
                                                            </div>
                                                        </div> <br />
                                                    </div> <br />
                                                </div> <div className="et_pb_column et_pb_column_1_5 et_pb_column_11  et_pb_css_mix_blend_mode_passthrough">


                                                    <div className="et_pb_module et_pb_blurb et_pb_blurb_1 seu-dinheiro-blurb-item  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">


                                                        <div className="et_pb_blurb_content">
                                                            <div className="et_pb_main_blurb_image"><span className="et_pb_image_wrap"><img loading="lazy" width="176" height="181" src={IMG_EMAIL} alt="" className="et-waypoint et_pb_animation_top wp-image-63" /></span></div>
                                                            <div className="et_pb_blurb_container">
                                                                <h4 className="et_pb_module_header"><span>VERIFIQUE SEU E-MAIL E TELEFONE</span></h4>
                                                                <div className="et_pb_blurb_description"><div className="number"><span>02</span></div>
                                                                    <p>O time do InConta irá entrar em contato com a resposta de sua simulação. Em caso de alguma pendência você poderá regularizar.</p></div>
                                                            </div>
                                                        </div> <br />
                                                    </div> <br />
                                                </div> <div className="et_pb_column et_pb_column_1_5 et_pb_column_12  et_pb_css_mix_blend_mode_passthrough">


                                                    <div className="et_pb_module et_pb_blurb et_pb_blurb_2 seu-dinheiro-blurb-item  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">


                                                        <div className="et_pb_blurb_content">
                                                            <div className="et_pb_main_blurb_image"><span className="et_pb_image_wrap"><img loading="lazy" width="174" height="181" src={IMG_CONTRACT} alt="" className="et-waypoint et_pb_animation_top wp-image-62" /></span></div>
                                                            <div className="et_pb_blurb_container">
                                                                <h4 className="et_pb_module_header"><span>ASSINE SEU CONTRATO</span></h4>
                                                                <div className="et_pb_blurb_description"><div className="number"><span>03</span></div>
                                                                    <p>Um procedimento totalmente seguro e digital, sem nenhum papel, você receberá o seu contrato no e-mail cadastrado.</p></div>
                                                            </div>
                                                        </div> <br />
                                                    </div> <br />
                                                </div> <div className="et_pb_column et_pb_column_1_5 et_pb_column_13  et_pb_css_mix_blend_mode_passthrough">


                                                    <div className="et_pb_module et_pb_blurb et_pb_blurb_3 seu-dinheiro-blurb-item  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">


                                                        <div className="et_pb_blurb_content">
                                                            <div className="et_pb_main_blurb_image"><span className="et_pb_image_wrap"><img loading="lazy" width="174" height="181" src={IMG_PAYMENT} alt="" className="et-waypoint et_pb_animation_top wp-image-65" /></span></div>
                                                            <div className="et_pb_blurb_container">
                                                                <h4 className="et_pb_module_header"><span>RECEBA SEU PAGAMENTO</span></h4>
                                                                <div className="et_pb_blurb_description"><div className="number"><span>04</span></div>
                                                                    <p>Após tudo assinado e aprovado, é só receber o dinheiro em conta. Simples, rápido e seguro.</p></div>
                                                            </div>
                                                        </div> <br />
                                                    </div> <br />
                                                </div> <div className="et_pb_column et_pb_column_1_5 et_pb_column_14  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                                    <div className="et_pb_module et_pb_blurb et_pb_blurb_4 seu-dinheiro-blurb-item  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">


                                                        <div className="et_pb_blurb_content">
                                                            <div className="et_pb_main_blurb_image"><span className="et_pb_image_wrap"><img loading="lazy" width="174" height="181" src={IMG_FALLOW} alt="" className="et-waypoint et_pb_animation_top wp-image-64" /></span></div>
                                                            <div className="et_pb_blurb_container">
                                                                <h4 className="et_pb_module_header"><span>MANTENHA SEMPRE SEU  CADASTRO ATUALIZADO</span></h4>
                                                                <div className="et_pb_blurb_description"><div className="number"><span>05</span></div>
                                                                    <p>Assim sempre irá acompanhar notícias, dicas e novidades.</p></div>
                                                            </div>
                                                        </div> <br />
                                                    </div> <br />
                                                </div>


                                            </div>


                                        </div> <div id="wrapper-tirar-duvidas" className="et_pb_section et_pb_section_6 et_pb_with_background et_section_regular">




                                            <div className="et_pb_row et_pb_row_7">
                                                <div className="et_pb_column et_pb_column_1_2 et_pb_column_15  et_pb_css_mix_blend_mode_passthrough">


                                                    <div className="et_pb_module et_pb_text et_pb_text_7  et_pb_text_align_left et_pb_bg_layout_light">


                                                        <div className="et_pb_text_inner"><h2>Tire aqui todas as suas<br /> <strong>dúvidas</strong> ou entre em contato<br /> com a nossa equipe.</h2></div>
                                                    </div> <br /><div className="et_pb_button_module_wrapper et_pb_button_2_wrapper et_pb_button_alignment_left et_pb_module ">
                                                        <a className="et_pb_button et_pb_button_2 et_pb_bg_layout_light" href="https://incontadigital.com.br/#footer-global" data-et-has-event-already="true">Saiba mais</a>
                                                    </div>
                                                </div> <div className="et_pb_column et_pb_column_1_2 et_pb_column_16  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                                    <div className="et_pb_module et_pb_accordion et_pb_accordion_1">


                                                        <div className="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_4  et_pb_toggle_open">


                                                            <h5 className="et_pb_toggle_title">Como consigo fazer um empréstimo consignado?</h5>
                                                            <div className="et_pb_toggle_content clearfix">O InConta conecta você ao crédito. Aqui além de levarmos excelentes condições, fazemos com que você economize o seu tempo! Você, servidor público, aposentado ou pensionista, verifique se o onde você trabalha possui convênio com a Financeira BRB para que você consiga contratar o seu crédito com a gente, sem sair de casa.</div>
                                                        </div> <div className="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_5  et_pb_toggle_close">


                                                            <h5 className="et_pb_toggle_title">Os juros do consignado são mais baratos?</h5>
                                                            <div className="et_pb_toggle_content clearfix">Sim! Os juros do crédito consignado são realmente mais baratos, isto ocorre também pelo fato do pagamento das parcelas pactuadas com a Instituição Financeira serem descontadas diretamente da folha de pagamento ou benefício beneficiário do contratante.</div>
                                                        </div> <div className="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_6  et_pb_toggle_close">


                                                            <h5 className="et_pb_toggle_title">Qual o máximo que posso pegar de empréstimo?</h5>
                                                            <div className="et_pb_toggle_content clearfix">Dependerá da sua margem consignável, no entanto, por CPF o limite de operação é de R$ 500.000,00.</div>
                                                        </div> <div className="et_pb_toggle et_pb_module et_pb_accordion_item et_pb_accordion_item_7  et_pb_toggle_close">


                                                            <h5 className="et_pb_toggle_title">Os juros aumentam com mais parcelas?</h5>
                                                            <div className="et_pb_toggle_content clearfix">Não. Independente do prazo da operação contratada as taxas de juros não aumentam com mais parcelas e nem diminuem com a redução do prazo e por falar em parcelas, para dirimir todas as dúvidas elas não sofrem alterações no decorrer do contrato.</div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>


                                        </div> 		</div><br />
                                </div><br />
                            </div> <br />


                        </article> <br />



                    </div>

                    <footer className="et-l et-l--footer" id="contato">
                        <div className="et_builder_inner_content et_pb_gutters3">
                            <div id="footer-global" className="et_pb_section et_pb_section_0_tb_footer et_pb_with_background et_section_regular">
                                <div className="et_pb_row et_pb_row_0_tb_footer">
                                    <div className="et_pb_column et_pb_column_1_2 et_pb_column_0_tb_footer  et_pb_css_mix_blend_mode_passthrough">
                                        <div className="et_pb_module et_pb_text et_pb_text_2_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                                            <div className="et_pb_text_inner"><h2>Informações de <strong>Contato</strong></h2></div>
                                        </div> <br />
                                        <div className="et_pb_module et_pb_text et_pb_text_1_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                                            <div className="et_pb_text_inner"><h3><strong>Endereço</strong></h3>
                                                <p>Avenida Tocantins, n. 375<br /> Edifício Édson Paniago, 2° Andar, Setor Central.<br /> Goiânia, Goiás – CEP 74.015-010 </p>
                                                <h3><strong>E-mail</strong></h3>
                                                <p>administrativo@rbcb.com.br</p>
                                                <h3><strong>Contato</strong></h3>
                                                <p>+55 62 3223-3215</p></div>
                                        </div> <br />
                                    </div>
                                    <div className="et_pb_column et_pb_column_1_2 et_pb_column_1_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                        <div className="et_pb_module et_pb_text et_pb_text_2_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                                            <div className="et_pb_text_inner"><h2>Fale&nbsp;<strong>Conosco</strong></h2></div>
                                        </div><br />
                                        <div className="et_pb_module et_pb_code et_pb_code_0_tb_footer form-wrap">
                                            <div className="et_pb_code_inner"><div role="form" className="wpcf7" id="wpcf7-f5-o1" lang="pt-BR" dir="ltr">
                                                <div className="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true"></p> <ul></ul></div>
                                                <form action="/#wpcf7-f5-o1" method="post" className="wpcf7-form init" noValidate="noValidate" data-status="init">
                                                    <p><span className="wpcf7-form-control-wrap your-name"><input type="text" name="your-name" value="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Seu nome:" /></span></p>
                                                    <p><span className="wpcf7-form-control-wrap your-email"><input type="email" name="your-email" value="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="Seu e-mail:" /></span></p>
                                                    <p><span className="wpcf7-form-control-wrap your-phone"><input type="text" name="your-phone" value="" size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required tel" aria-required="true" aria-invalid="false" placeholder="Telefone de contato:" /></span></p>
                                                    <p><span className="wpcf7-form-control-wrap your-message"><textarea name="your-message" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Mensagem:"></textarea></span></p>
                                                    <p><input type="submit" value="Enviar" className="wpcf7-form-control wpcf7-submit" /><span className="ajax-loader"></span></p>
                                                    <div className="wpcf7-response-output" aria-hidden="true"></div>
                                                </form>
                                            </div>
                                            </div>
                                        </div>


                                    </div>


                                </div> <div id="in-conta-footer" className="et_pb_row et_pb_row_1_tb_footer et_pb_row_4col">
                                    <div className="et_pb_column et_pb_column_1_4 et_pb_column_2_tb_footer  et_pb_css_mix_blend_mode_passthrough">


                                        <div className="et_pb_module et_pb_image et_pb_image_0_tb_footer">


                                            <span className="et_pb_image_wrap "><img loading="lazy" src={IMG_LOGO_GRAY} alt="" title="logo-Inconta-gray" height="auto" width="auto" className="wp-image-135" /></span>
                                        </div><div className="et_pb_module et_pb_text et_pb_text_5_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">


                                            <div className="et_pb_text_inner">A InConta é uma plataforma digital. Assim, seguimos na missão em levar a linha de crédito de uma maneira rápida e fácil. O crédito conectado a você. Somos facilitadores. Encurtamos o caminho entre o cliente e as soluções financeiras.</div>
                                        </div> <br />
                                    </div> <div className="et_pb_column et_pb_column_1_4 et_pb_column_3_tb_footer  et_pb_css_mix_blend_mode_passthrough">


                                        <div className="et_pb_module et_pb_text et_pb_text_6_tb_footer mtb-title  et_pb_text_align_left et_pb_bg_layout_light">


                                            <div className="et_pb_text_inner"><h3><strong>Ajuda e atendimento</strong></h3>
                                                <p>Avenida Tocantins, n. 375<br /> Edifício Édson Paniago, 2° Andar, Setor Central.<br /> Goiânia, Goiás – CEP 74.223-042</p></div>
                                        </div> <br />
                                    </div> <div className="et_pb_column et_pb_column_1_4 et_pb_column_4_tb_footer  et_pb_css_mix_blend_mode_passthrough">


                                        <div className="et_pb_module et_pb_text et_pb_text_7_tb_footer mtb-title  et_pb_text_align_left et_pb_bg_layout_light">


                                            <div className="et_pb_text_inner"><h3><strong>Navegação</strong></h3>
                                                <p><a href="https://incontadigital.com.br/">Simulador</a></p>
                                                <p><a href="https://incontadigital.com.br/">Vantagens</a></p>
                                                <p><a href="https://incontadigital.com.br/">Blog</a></p>
                                                <p><a href="https://incontadigital.com.br/">Dúvidas</a></p>
                                                <p><a href="https://incontadigital.com.br/">Contato</a></p></div>
                                        </div> <br />
                                    </div> <div className="et_pb_column et_pb_column_1_4 et_pb_column_5_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                        <div className="et_pb_module et_pb_text et_pb_text_8_tb_footer mtb-title  et_pb_text_align_left et_pb_bg_layout_light">


                                            <div className="et_pb_text_inner"><h3><strong>Institucional</strong></h3>
                                                <p><a href="#somos">Quem somos</a></p>
                                                <p><a href="#contato">Trabalhe conosco</a></p>
                                                <p><a href="https://incontadigital.com.br/">Mapa do Site</a></p></div>
                                        </div> <br /><div className="et_pb_module et_pb_text et_pb_text_9_tb_footer mtb-title  et_pb_text_align_left et_pb_bg_layout_light">


                                            <div className="et_pb_text_inner"><h3><strong>Redes Sociais</strong></h3></div>
                                        </div> <br /><ul className="et_pb_module et_pb_social_media_follow et_pb_social_media_follow_0_tb_footer clearfix  et_pb_bg_layout_light">


                                            <li className="et_pb_social_media_follow_network_0_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-facebook et_pb_social_media_follow_network_0_tb_footer"><a href="https://web.facebook.com/InConta-Cr%C3%A9dito-Digital-105285145033247" className="icon et_pb_with_border" title="Follow on Facebook" target="_blank"><span className="et_pb_social_media_follow_network_name" aria-hidden="true">Follow</span></a></li><li className="et_pb_social_media_follow_network_1_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-instagram et_pb_social_media_follow_network_1_tb_footer"><a href="https://www.instagram.com/incontadigital/" className="icon et_pb_with_border" title="Follow on Instagram" target="_blank"><span className="et_pb_social_media_follow_network_name" aria-hidden="true">Follow</span></a></li><li className="et_pb_social_media_follow_network_2_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-twitter et_pb_social_media_follow_network_2_tb_footer"><a href="#" className="icon et_pb_with_border" title="Follow on Twitter" target="_blank"><span className="et_pb_social_media_follow_network_name" aria-hidden="true">Follow</span></a></li><li className="et_pb_social_media_follow_network_3_tb_footer et_pb_social_icon et_pb_social_network_link  et-social-linkedin et_pb_social_media_follow_network_3_tb_footer"><a href="https://www.linkedin.com/company/inconta-cr%C3%A9dito-digital/about/?viewAsMember=true" className="icon et_pb_with_border" title="Follow on LinkedIn" target="_blank"><span className="et_pb_social_media_follow_network_name" aria-hidden="true">Follow</span></a></li>
                                        </ul>
                                    </div>


                                </div> <div className="et_pb_row et_pb_row_2_tb_footer">
                                    <div className="et_pb_with_border et_pb_column_4_4 et_pb_column et_pb_column_6_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                        <div id="somos" className="et_pb_module et_pb_text et_pb_text_10_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">


                                            <div className="et_pb_text_inner">
                                                <p>A InConta Crédito Digital, CNPJ 41.561.368/0001-06, pessoa jurídica que não constitui-se como uma Instituição Financeira, trata-se de um Correspondente no País do Banco Original (CNPJ 92.894.922/0001-08), Banco Rodobens (CNPJ 33.603.457/0001-40) nos moldes entabulados pelas Resoluções 3.954/2011 e 4.935/2021 do Banco Central do Brasil.</p>
                                                <p>Atua ainda como empresa parceira Rede Brasileira de Correspondentes e Business Ltda., esta correspondente no país da BRB – Crédito, Financiamento e Investimento S/A, doravante denominada Financeira BRB, CNPJ 33.136.999/0001-43.</p>
                                                <p>A InConta Crédito Digital está autorizada a intermediar propostas de produtos das instituições financeiras acima descritas em seus canais de venda, cumprindo rigorosamente a política de crédito da Instituição Correspondida, sendo atribuição privativa desta as condições operacionais de cada produto, incluindo taxas de juros e prazos negociais.</p>
                                                <p>Empresa integrante do Hub Alfa de Inovação – Alfa Collab e Associação Brasileira de Fintechs – ABFintechs.</p>
                                            </div>
                                        </div> <br />
                                    </div>


                                </div> <div id="policy-soluti" className="et_pb_row et_pb_row_3_tb_footer et_pb_row_3-4_1-4">
                                    <div className="et_pb_column et_pb_column_3_4 et_pb_column_7_tb_footer  et_pb_css_mix_blend_mode_passthrough">


                                        <div className="et_pb_module et_pb_text et_pb_text_11_tb_footer policy  et_pb_text_align_left et_pb_bg_layout_light">


                                            <div className="et_pb_text_inner"><ul>
                                                <li><a href="https://incontadigital.com.br/">Política de privacidade</a></li>
                                                <li><a href="https://incontadigital.com.br/">Termos de uso</a></li>
                                                <li><a href="https://incontadigital.com.br/">Ouvidoria dos parceiros</a></li>
                                            </ul></div>
                                        </div>
                                    </div>
                                    {/* <div className="et_pb_column et_pb_column_1_4 et_pb_column_8_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                    <div className="et_pb_module et_pb_image et_pb_image_1_tb_footer">


                                        <span className="et_pb_image_wrap "><img loading="lazy" src={IMG_SOLUTI} alt="" title="soluti" height="auto" width="auto" className="wp-image-140" /></span>
                                    </div>
                                </div> */}


                                </div>


                            </div> 		
                        </div>
                    </footer>
                </div>


            </div>
        </div>

    )
}

